import React from 'react';
import getLoginStatus from './getLoginStatus';

const LoginButton = (params) => {

	const {onLogin, onLogout} = params;

	const onClickLogin = () => {
		onLogin();

	};

	const onClickLogout = () => {

		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');

		onLogout();

	};

	const { isLoggedIn } = getLoginStatus();

	if (isLoggedIn)
	{
		return (
			<button className='menuLoginButton ml-auto' onClick={onClickLogout}>Logout</button>
		);
	}
	else
	{
		return (
			<button className='menuLoginButton ml-auto' onClick={onClickLogin}>Login</button>
		);
	}

};

export default LoginButton;