import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { apiGet } from './apiCall';

const Archive = () => {
	const [owlsArchive, setOwlsArchive] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);
	const [fileName, setFileName] = useState('');

	const onClick = (filename) => {
		setFileName(filename);
	};

	useEffect(() => {
		const endPoint = 'owlsnewsletters';

		apiGet(endPoint)
			.then(data => {
				setOwlsArchive(data.content.OwlsNewsletters);
				setHttpStatus(data.status);
			})
			.catch(error => {
				setHttpStatus(error.status);

			});
	}, []);


	switch (httpStatus){
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}


	if (fileName !== '')
	{

		const route = {
			pathname: '/newsletter',
			search: fileName
		};

		return <Redirect to={ route }   />;

	}

	return (

			<>
				<h1 className='archiveHeading'>Newsletter Archive</h1>

				{owlsArchive.length === 0 ? (
					<>
						<div>Loading...</div>
					</>
				) : (

					<NewsletterLinks archive={owlsArchive} onClick={onClick}/>

				)
				}
			</>

	);
};

const NewsletterLinks = (props) => {

	const { archive, onClick } = props;

	const base = archive.base;
	if (!base)
		return null;

	var newsletters = archive.newsletters;


	const newsletterLinkArray = () => {

		const newsletterArray = [];

		var newsletterYear = {};

		var currentYear = null;

		newsletters.forEach((newsletter) => {
			if (currentYear !== newsletter.year) {


				newsletterYear = {};

				newsletterYear.year = newsletter.year;
				newsletterYear.content = [];
				newsletterArray.push(newsletterYear);

				currentYear = newsletter.year;

			}

			var newsletterContent = {};
			newsletterContent.month = newsletter.month;
			newsletterContent.filename = newsletter.filename;

			newsletterYear.content.push(newsletterContent);

		});


		return newsletterArray;

	};

	const newsletterLinks = newsletterLinkArray().map((newsletter, index) => {
		const links = (
			<React.Fragment>
				<Row><Col key={index} className='archiveYear'>{newsletter.year}</Col></Row>
				<Row>
					{
						newsletter.content.map((content, index) => {
							return (
								<Col md="auto" key={index}>
									<Button className='archiveButton' onClick={() => onClick(content.filename)}>
										{content.month}
									</Button>

								</Col>
							);
						})
					}
				</Row>
			</React.Fragment>

		);

		return links;

	});

	return [newsletterLinks];
};

export default Archive;
