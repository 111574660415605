
import React, {useState, useEffect} from 'react';
import {Row, Col, Image, Button, FormControl, FormCheck, Dropdown, DropdownButton} from 'react-bootstrap';

import { apiGet } from '../apiCall';

import DeleteImage from '../images/delete.svg';
import AddImage from '../images/add.svg';

import '../Admin.css';

const OwlsNewsletterContent = () => {

	const getCalendarDate = () => {

		return '06-2020';

	};

	const endPoint = 'owlscontent';

	const [newsletterSections, setNewsletterSections] = useState([]);
	const [calendarDate, setCalendarDate] = useState(getCalendarDate());
	const [httpStatus, setHttpStatus] = useState(200);
	const [httpStatusMessage, setHttpStatusMessage] = useState('');
	const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

	// const [color, setColor] = useState('')

	const handleChangeDate = ( event ) => {
		setCalendarDate( event.target.value );
	};

	const handleChangeHeading = (i, e) => {

		const field = e.target.value;

		const values = [...newsletterSections];

		values[i].heading = field;

		setNewsletterSections(values);

	};

	const handleChangeInclude = (i, e) => {

		const field = e.target.value;

		const values = [...newsletterSections];

		values[i].include = field;

		setNewsletterSections(values);

	};

	const handleChangeColor = (i, e) => {

		// console.log(e)

		const field = e.target.innerText;

		const values = [...newsletterSections];

		values[i].color = field;

		// setColor(field);

		setNewsletterSections(values);

	};

	const handleChangeContent = (i, e) => {

		const field = e.target.value;

		// console.log("Content:" + field)

		const values = [...newsletterSections];

		values[i].content = field;

		setNewsletterSections(values);

	};

	const handleChange = (i, event) => {
		const values = [...newsletterSections];
		values[i] = event.target.value;

		var isDisabled = false;
		values.forEach(value => {
			if (!value.trim())
				isDisabled = true;
		});

		setIsUpdateDisabled(isDisabled);
		setNewsletterSections(values);
	};

	const handleAdd = () => {
		const values = [...newsletterSections];
		values.push( {} );
		setIsUpdateDisabled(true);
		setNewsletterSections(values);
	};

	const handleRemove = (i) => {
		const values = [...newsletterSections];
		values.splice(i, 1);

		setIsUpdateDisabled(false);

		setNewsletterSections(values);
	};

	const handleUpdate = () => {
		const payload = {};
		payload.OwlsContent = {};

		payload.calendar = calendarDate;

		payload.sections = [];

		newsletterSections.forEach((field) => {
			payload.sections.push(field);
		});


		alert(JSON.stringify(payload));


		// apiPut(endPoint, payload)
		// 	.then(data => {
		// 		setHttpStatus(data.status);

		// 	})
		// 	.catch(error => {
		// 		setHttpStatus(error.status);

		// 	});

		// switch (httpStatus){

		// case 200:
		// 	setHttpStatusMessage('Update Complete');
		// 	break;

		// default:
		// 	setHttpStatusMessage(`Error on update: ${httpStatus}`);

		// }

		// setIsUpdateDisabled(true);

	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setHttpStatusMessage('');
		}, 5000);
		return () => clearTimeout(timer);
	}, [httpStatusMessage]);

	useEffect(() => {

		apiGet(endPoint)
			.then(data => {
				const values = [...data.content.OwlsContent.sections ];
				setNewsletterSections(values);
				setCalendarDate( data.content.OwlsContent.calendar);
				setHttpStatusMessage('');
			})
			.catch(error => {
				setHttpStatus(error.status);
				setHttpStatusMessage(`Error on GET: ${error.status}`);

			});
	}, []);

	var textList = newsletterSections.map((field, idx) => {

		const { heading, include, color, content } = field;

		// setColor(rowColor)

		return (

			<Row key={idx}>

				<Col xs={1}>
					<Image src={DeleteImage} onClick={() => handleRemove(idx)} />
				</Col>

				<Col xs={11}>

					<Row className='adminText'>
						<Col className='adminCheck'>
							<FormCheck
								checked={include}
								onChange={e => handleChangeInclude(idx, e)} />

						</Col>

						<Col>
							<FormControl
								value={heading}
								onChange={e => handleChangeHeading(idx, e)} />
						</Col>

						<Col>
							{color}
							<DropdownButton
								title={color}
								value={color}>
								{/* onSelect={e => handleChangeColor(idx, e)} > */}

								<Dropdown.Item onClick={e => handleChangeColor(idx, e)} key={'key-maroon'} eventKey={'E-Maroon'}>Maroon</Dropdown.Item>
								<Dropdown.Item onClick={e => handleChangeColor(idx, e)} key={'key-blue'} eventKey={'E-Blue'}>Blue</Dropdown.Item>
								<Dropdown.Item onClick={e => handleChangeColor(idx, e)} key={'key-red'} eventKey={'E-Red'}>Red</Dropdown.Item>

							</DropdownButton>
						</Col>

					</Row>

					<Row className='adminText'>

						<Col>

							<FormControl
								placeholder="Content"
								value={content}
								onChange={e => handleChangeContent(idx, e)} />

						</Col>

					</Row>

				</Col>

			</Row>

		);

	});

	return (

		<Row className='adminControl'>

			<Col xs={1}>

			</Col>

			<Col>
				<Row className='adminMarginTop adminMarginBottom'>
					<Col>Owls Newsletter Content</Col>
				</Row>

				{textList}

				<Row className='adminMarginTop'>

					<Col xs={1}>
						<Image src={AddImage} onClick={() => handleAdd()} />
					</Col>
					<Col xs={11}>
						<Button className='adminButton' disabled={isUpdateDisabled} onClick={handleUpdate}>Update Newsletter Content</Button>
					</Col>

				</Row>

				<Row className='adminMarginTop adminMarginBottom adminError'>
					<Col>
						{httpStatusMessage}
					</Col>
				</Row>
			</Col>

			<Col xs={2}></Col>

		</Row>

	);

};

export default OwlsNewsletterContent;