import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

import axios from 'axios'

axios.defaults.baseURL = "https://api.owlsofelgin.com/";
// axios.defaults.baseURL = "https://davgertmm3.execute-api.us-east-2.amazonaws.com/prod/";


ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));

