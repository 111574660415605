import React, { useState, useEffect } from 'react';
import {Container} from 'react-bootstrap';

import HomeContent from './HomeContent';
import HomeLoggedIn from './HomeLoggedIn';

import getLoginStatus from './getLoginStatus';

const Home = () =>  {

	const [isLoggedIn, setIsLoggedIn] = useState(false);


	useEffect(() => {

		var {isLoggedIn} = getLoginStatus();

		setIsLoggedIn(isLoggedIn);

	});




	return (

		<Container fluid>

			<HomeContent />

			{ isLoggedIn &&

            <HomeLoggedIn />

			}

		</Container>
	);

};

export default Home;