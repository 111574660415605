import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ListGroup, ListGroupItem} from 'react-bootstrap';

import { apiGet } from './apiCall';

const OwlOfTheMonth = () => {
	const [owlsOfTheMonth, setOwlsOfTheMonth] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);

	useEffect(() => {
		const endPoint = 'owlsofthemonth';
		apiGet(endPoint)
			.then(data => {
				setOwlsOfTheMonth(data.content.OwlsOfTheMonth);
			})
			.catch(error => {
				setHttpStatus(error.status);

			});
	}, []);

	switch (httpStatus){
	case 404:
		break;
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	return (

		<ListGroup  className='owlofthemonthListGroup'>

			<h3 className='owlofthemonthHeader'>Owl of the Month</h3>

			{owlsOfTheMonth.map((item, index) => (
				<ListGroupItem className='owlofthemonthListGroupItem' key={index}>{item}</ListGroupItem>
			))}

		</ListGroup>

	);
};

export default OwlOfTheMonth;
