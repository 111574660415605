import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { apiGet } from './apiCall';

const Officers = () => {
	const [owlsOfficers, setOwlsOfficers] = useState({});
	const [httpStatus, setHttpStatus] = useState(200);

	useEffect(() => {
		const endPoint = 'owlsofficers';
		apiGet(endPoint)
			.then(data => {
				setOwlsOfficers(data.content.OwlsOfficers);
				setHttpStatus(data.status);
			})
			.catch(error => {
				setHttpStatus(error.status);

			});
	}, []);

	switch (httpStatus){
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	return (
		<div>
			<Container>
				<h2 className='officersOfficersHeading'>Officers</h2>

				{ JSON.stringify(owlsOfficers) === '{}' ? (
					<>
						<div>Loading...</div>
					</>
				) : (

					<>
						<Row>
							<Col className='officersTitle'>President</Col>
							<Col className='officersOfficer'>{owlsOfficers.President}</Col>
						</Row>
						<Row>
							<Col className='officersTitle'>Vice President</Col>
							<Col className='officersOfficer'>{owlsOfficers.VicePresident}</Col>
						</Row>
						<Row>
							<Col className='officersTitle'>Treasurer</Col>
							<Col className='officersOfficer'>{owlsOfficers.Treasurer}</Col>
						</Row>
						<Row>
							<Col className='officersTitle'>Secretary</Col>
							<Col className='officersOfficer'>{owlsOfficers.Secretary}</Col>
						</Row>

						<h2 className='officersTrusteeHeading'>Trustees</h2>

						<ListGroup>

							{owlsOfficers.Trustees &&
								owlsOfficers.Trustees.map((item, index) => (
									<ListGroupItem className='officersListGroupItem' key={index}>
										{item}
									</ListGroupItem>
								))
							}

						</ListGroup>

					</>

				)
				}

			</Container>
		</div>
	);
};

export default Officers;
