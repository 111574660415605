import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { apiGet } from './apiCall';

const DonationOfTheMonth = () => {
	const [owlsDonation, setOwlsDonation] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);

	useEffect(() => {
		const endPoint = 'owlsdonation';

		apiGet(endPoint)
			.then(data => {
				setOwlsDonation(data.content.OwlsDonation);
			})
			.catch(error => {
				setHttpStatus(error.status);

			});
	}, []);

	switch (httpStatus){
	case 404:
		break;
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	return (

		<ListGroup className='donationListGroup'>

			<h3 className='donationHeader'>Donation of the Month</h3>

			{owlsDonation.map((item, index) => (
				<ListGroupItem className='donationListGroupItem' key={index}>{item}</ListGroupItem>
			))}

		</ListGroup>

	);
};

export default DonationOfTheMonth;
