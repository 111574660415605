import React from 'react';
import {Container, Row, Col, Image } from 'react-bootstrap';

import imgOwl from './images/Owl-Home.png';

const HomeContent = () => {

	return(

		<Container fluid  className='homeContainer'>

			<Row>
				<Col>
					<Image src={imgOwl}  rounded alt="Owls of Elgin - Since 1933" />
				</Col>

				<Col className='homeColumnCenter'>
					<Row className="justify-content-center" >
						<h3>Owl&apos;s Athletic Club</h3>
					</Row>

					<Row className="justify-content-center">
                  214 E. Chicago St.
					</Row>
					<Row className="justify-content-center">
                  Elgin, IL 60120
					</Row>

					<Row className="justify-content-center">
                  847 888-0110
					</Row>

				</Col>

			</Row>

		</Container>

	);

};

export default HomeContent;
