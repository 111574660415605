
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { apiGet } from './apiCall';

const Newsletter = ( params ) => {

	const [newsletterStatus, setNewsletterStatus] = useState('');
	const [httpStatus, setHttpStatus] = useState(200);
	const [redirectHome, setRedirectHome] = useState(false);

	useEffect(() => {

		var endPoint = 'owlsnewsletter';

		var filename = (params && params.location && params.location.search ? params.location.search.slice(1) : '');
		if (filename !== '')
			endPoint += '?filename=' + filename;

		apiGet(endPoint)
			.then(data => {
				setHttpStatus(data.status);
				setNewsletterStatus('Loading');

				const buffer = new Buffer(data.content.OwlsNewsletter.pdf, 'base64');
				const file = new Blob([buffer], {type: 'application/pdf'});

				const fileURL = URL.createObjectURL(file);

				// var downloadName = filename ? filename : 'OwlsNewsletter.pdf';

				// Direct Download
				// const link = document.createElement('a');
				// link.href = fileURL;
				// link.setAttribute('download', downloadName);
				// document.body.appendChild(link);
				// link.click();

				// Open the URL on new Tab
				window.open(fileURL);
				setNewsletterStatus('Content in new tab');

				// Unconditionally revoke and redirect
				URL.revokeObjectURL(file);
				setRedirectHome(true);

			})
			.catch(error => {
				setNewsletterStatus('Error: ' + JSON.stringify(error.message));
				setHttpStatus(error.status);

			});
	}, []);

	switch (httpStatus){
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}
	if (redirectHome)
		return <Redirect to='/' />;

	return (
		<div>
			<h1>Newsletter</h1>

			<div>{newsletterStatus}</div>

		</div>
	);

};

export default Newsletter;
