
import React, { useState , useEffect} from 'react';
import { Redirect } from 'react-router-dom';

import { apiPost } from './apiCall';

import jwt from 'jsonwebtoken';

const Login = ()  =>  {

	useEffect(() => {

		localStorage.removeItem('isAdmin');
		localStorage.removeItem('authToken');


	}, []);

	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');

	const [ errorMessage, setErrorMessage ] = useState('');

	const [ redirectRoot, setRedirectRoot ] = useState(false);

	const validateForm = () => {

		const validUser = username && username.length > 0 ? true : false;
		const validPassword = password && password.length > 0 ? true : false;

		return validUser && validPassword;
	};

	const onChangeUsername = ( e ) => {

		setUsername( e.target.value );

	};

	const onChangePassword = ( e ) => {

		setPassword( e.target.value );

	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const endPoint = 'owlsauth';

		const payload = {
			'userId': username.toLowerCase(),
			'password': password
		};

		var loginStatus;

		localStorage.removeItem('isAdmin');
		localStorage.removeItem('authToken');

		await apiPost(endPoint, payload)
			.then(data => {

				const token = data.content.token;
				if (!token) {
					loginStatus = '403';
					return;
				}

				const decodedToken = jwt.decode(token);
				if (!decodedToken) {
					loginStatus = '403';
					return;
				}

				const groups = decodedToken['cognito:groups'] ? decodedToken['cognito:groups'] : [];
				if (!groups || groups.length === 0) {
					loginStatus = '403';
					return;
				}

				var isAdmin = groups.includes('Admin');

				localStorage.setItem('isAdmin', isAdmin);
				localStorage.setItem('authToken', data.content.token);

				loginStatus = data.status;

			})
			.catch(error => {
				loginStatus = error.status;

			});

		if (loginStatus === 200) {
			setRedirectRoot(true);
		}
		else {
			setErrorMessage('Invalid Login. Please retry');
		}

	};

	if (redirectRoot) {
		return <Redirect to='/' />;

	}

	return (
		<div className="formLogin">

			<p>
				<label className="formLabel" htmlFor="username">User Name</label>
				<input className="formInput"
					name="username"
					placeholder="Enter username"
					type="text"
					value={username}
					onChange={onChangeUsername}
					autoFocus
				/>
			</p>

			<p>
				<label className="formLabel" htmlFor="password">Password</label>
				<input className="formInput"
					name="password"
					placeholder="Enter password"
					value={password}
					onChange={onChangePassword}
					type="password"
				/>
			</p>

			<p>
				<button className='loginButton formButton'
					disabled={!validateForm()}
					onClick={handleSubmit}
					type="button">
              Login
				</button>
			</p>

			<p>
				<label className='errorColor'>{errorMessage}</label>
			</p>

		</div>
	);
};

export default Login;
