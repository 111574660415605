import axios from 'axios';

const axiosClient = axios.create({


});

export const apiGet = async (apiParm) => {

	return new Promise((resolve, reject) => {

		var response = {};
		var headers = {};
		headers['Authorization'] =  `Bearer ${localStorage.getItem('authToken')}`;

		axiosClient
			.get(apiParm, {headers})
			.then(data => {
				response.status = data.status;
				response.content = data.data;

				resolve(response);

			})  // then
			.catch(error => {

				var err = error.response && error.response.data && error.response.data.status
					? error.response.data : {status: 500, message: 'Server Error'};

				console.log(`Axios error: (Get) Endpoint: ${apiParm}: ${err.status} - ${err.message}`);

				response.status = err.status;
				response.content = err.message;

				reject(response);

			});   // catch

	}); // promise

};  // apiGet

export const apiPost = async (apiParm, payload) => {

	return new Promise((resolve, reject) => {

		var response = {};

		axiosClient
			.post(apiParm, payload)
			.then(data => {
				response.status = data.status;
				response.content = data.data;

				resolve(response);
			})
			.catch(error => {

				var err = error.response && error.response.data && error.response.data.status
					? error.response.data : {status: 500, message: 'Server Error'};

				console.log(`Axios error: (Post) Endpoint: ${apiParm}: ${err.status} - ${err.message}`);

				response.status = err.status;
				response.content = err.message;

				reject(response);
			});

	});
};

export const apiPut = async (apiParm, payload) => {

	return new Promise((resolve, reject) => {

		var response = {};
		var headers = {};
		headers['Authorization'] =  `Bearer ${localStorage.getItem('authToken')}`;

		axiosClient
			.put(apiParm, payload, {headers})
			.then(data => {
				response.status = data.status;
				response.content = data.data;

				resolve(response);
			})
			.catch(error => {

				var err = error.response && error.response.data && error.response.data.status
					? error.response.data : {status: 500, message: 'Server Error'};

				console.log(`Axios error: (Put) Endpoint: ${apiParm}: ${err.status} - ${err.message}`);

				response.status = err.status;
				response.content = err.message;

				reject(response);
			});

	});

};

