import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { apiGet } from './apiCall';

const Advertisers = () => {
	const [owlsAdvertisers, setOwlsAdvertisers] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);

	useEffect(() => {
		const endPoint = 'owlsadvertisers';

		apiGet(endPoint)
			.then(data => {
				setOwlsAdvertisers(data.content.OwlsAdvertisers);
				setHttpStatus(data.status);
			})
			.catch(error => {
				setHttpStatus(error.status);

			});
	}, []);


	switch (httpStatus){
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	const eachAdvertiserLine = (item, index) => {

		var formatType;
		var content;
		var returnValue;

		Object.getOwnPropertyNames(item).forEach( val => {
			formatType = val;
			content = item[val];
		});

		switch (formatType){
		case 'H' :
			returnValue = <h4 className='advertiserDetail' key={index}>{content}</h4>;
			break;
		case 'D' :
			returnValue = <p className='advertiserDetail' key={index}>{content}</p>;
			break;
		case 'B' :
			returnValue = content.split(';').map((bullet) => {
				return (
					<Row key={index} className='advertiserRow'>
						<Col xs={2} ></Col>
						{
							bullet.split('|').map((l, i) => {
								return <Col key={i} xs={5} className='text-left'><li key={i}>{l}</li></Col>;
							})
						}
						<Col xs={1}></Col>
					</Row>
				);

			});
			break;
		case 'E' :
			returnValue = <p><a className='advertisersAnchor'  key={index} href={'mailto:' + content + '?subject=Owls of Elgin Email Inquiry'}>{content}</a></p>;
			break;
		case 'P' :
			returnValue = <p><a className='advertisersAnchor' key={index} href={'tel:' + content}>{content}</a></p>;
			break;
		case 'W' :
			returnValue = <p><a className='advertisersAnchor'  key={index} href={'http://' + content + ''}>{content}</a></p>;
			break;
		default:
			returnValue = <p key={index}>{content}</p>;
		}   // end switch

		return returnValue;

	};

	const formatAdvertiser = (advertiser) => {

		return (
			<Col className='advertiserCell'>
				{
					advertiser.map( (item, index) => {
						return (
							eachAdvertiserLine(item, index)
						);
					})
				}
			</Col>
		);
	};


	return (
		<React.Fragment>
			<h1 className='advertiserHeading'>Advertisers</h1>

			{owlsAdvertisers.length === 0 ? (
          <>
            <div>Loading...</div>
          </>
			) : (

				owlsAdvertisers.map((item, index) => {

					return (

						index % 2 === 0 ?
							<Row key={index}>
								{formatAdvertiser(owlsAdvertisers[index].Advertiser) }
								{formatAdvertiser(owlsAdvertisers[index+1].Advertiser)}
							</Row>
							:
							null

					);  // return
				})  // map

			) // conditional
			}

		</React.Fragment>
	);

};

export default Advertisers;
