import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { apiGet } from './apiCall';

const Sponsors = () => {
	const [owlsSponsors, setOwlsSponsors] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);

	useEffect(() => {
		var endPoint = 'owlssponsors';
		apiGet(endPoint)
			.then(data => {
				setOwlsSponsors(data.content.OwlsSponsors);
				setHttpStatus(data.status);
			})
			.catch(error => {
				setHttpStatus(error.status);
			});
	}, []);

	switch (httpStatus){
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	return (
		<Container>
			<h1 className='sponsorHeading'>Owl&apos;s Event Sponsors</h1>

			{owlsSponsors.length === 0 ? (
            <>
              <div>Loading...</div>
            </>
			) : (

            <>
              <Row className='sponsorHeader'>{renderTableHeader()}</Row>
              {renderTableData(owlsSponsors)}
            </>
			)
			}

		</Container>
	);
};

const renderTableHeader = () => {
	var header = [
		'Month',
		'Smelt and Wings',
		'Special Sunday Breakfast',
		'Big Breakfast',
		'Burgers'
	];

	return (

		header.map((key, index) => {

			return ( <Col key={index}>{key}</Col> );

		})


	);

};

const renderTableData = sp => {
	return sp.map((sponsor, index) => {
		const {
			month,
			SmeltAndWings,
			SpecialSundayBreakfast,
			BigBreakfast,
			Burgers
		} = sponsor;

		// Check for Summer (No Smelt...)
		// Alternate colors

		var monthColor = 'black';
		var smeltAndWingsColor = 'orangered';
		var specialSundayBreakfastColor = 'darkslateblue';
		var bigBreakfastColor = 'darkslategray';
		var burgerColor = 'maroon';

		if (['JUNE', 'JULY', 'AUGUST'].indexOf(month) >= 0 ) {

			smeltAndWingsColor = 'darkmagenta';
			specialSundayBreakfastColor = 'darkmagenta';
			bigBreakfastColor = 'darkmagenta';
		}

		return (
			<Row className='sponsorDetail' key={index}>
				<Col style={{color: monthColor}}>{month}</Col>
				<Col style={{color: smeltAndWingsColor, whiteSpace: 'pre-line'}}>{SmeltAndWings}</Col>
				<Col style={{color: specialSundayBreakfastColor, whiteSpace: 'pre-line'}}>{SpecialSundayBreakfast}</Col>
				<Col style={{color: bigBreakfastColor, whiteSpace: 'pre-line'}}>{BigBreakfast}</Col>
				<Col style={{color: burgerColor, whiteSpace: 'pre-line'}}>{Burgers}</Col>
			</Row>
		);
	});
};

export default Sponsors;
