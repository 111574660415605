import React  from 'react';
import { Container, Row, Col, } from 'react-bootstrap';

const Footer = () => {

	return (
		<Container fluid>
			<Row className='footerRow'>

				<Col xs={3} className='footerColumn'>
                    &copy; { new Date().getFullYear() } - Owls of Elgin
				</Col>

				<Col xs={5} className='footerColumn' ></Col>

				<Col xs={4}  className='footerColumn align-self-right' onClick={linkToMeanOldMike}>
                    React Development
				</Col>


			</Row>
		</Container>

	);

};

function linkToMeanOldMike(e){

	e.preventDefault();

	var win = window.open('http://meanoldmike.com', '_blank');
	win.focus();

}

export default Footer;