import jwt from 'jsonwebtoken';

const getLoginStatus = () => {

	var loginStatus = {
		isLoggedIn: false,
		isAdmin: false

	};

	var token = localStorage.getItem('authToken');

	if (!token) return loginStatus;

	const decodedToken = jwt.decode(token);
	if (!decodedToken) return loginStatus;

	const groups = decodedToken['cognito:groups'] ? decodedToken['cognito:groups'] : [];

	if (!groups || groups.length === 0) return loginStatus;

	const exp = decodedToken.exp;

	if (Date.now() >= exp * 1000) {
		return loginStatus;
	}

	groups.includes('Admin');
	loginStatus.isLoggedIn = true;
	loginStatus.isAdmin =  groups.includes('Admin');


	return loginStatus;

};

export default getLoginStatus;