import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { Switch, Route } from 'react-router-dom';

import Header from './components/Header';

import Footer from './components/Footer';

import Home from './components/Home';
import Advertisers from './components/Advertisers';

import Calendar from './components/Calendar';
import Officers from './components/Officers';
import Newsletter from './components/Newsletter';
import Archive from './components/Archive';
import Sponsors from './components/Sponsors';

import Admin from './components/Admin';
import Login from './components/Login';

const Main = () => (
	<div className="pageMain">

		<Switch>
			<Route exact path='/' component={Home}></Route>
			<Route exact path='/advertisers' component={Advertisers}></Route>
			{ /* No Facebook */ }
			<Route exact path='/calendar' component={Calendar}></Route>
			<Route exact path='/officers' component={Officers}></Route> 

			<Route exact path='/newsletter' component={Newsletter}></Route>

			<Route exact path='/archive' component={Archive}></Route>
			<Route exact path='/sponsors' component={Sponsors}></Route>

			<Route exact path='/admin' component={Admin}></Route>

			<Route exact path='/login' component={Login}></Route>

			<Route component={Home}></Route>
      
		</Switch>

	</div>
);

const App = () => {

	return (

		<div className="App">

			<div className="mainContainer">
				<Header />
				<Main />
				<Footer   />
			</div>

		</div>
      
	);

};

export default App;
