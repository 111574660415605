import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroupItem } from 'react-bootstrap';

import { NavLink, Redirect } from 'react-router-dom';

import headerLeft from './images/Owl-Left.png';
import headerRight from './images/Owl-Right.png';

import LoginButton from './LoginButton';

import getLoginStatus from './getLoginStatus';


const Navigation = () => {

	const [ redirectRoot, setRedirectRoot ] = useState(false);
	const [ redirectLogin, setRedirectLogin ] = useState(false);

	useEffect(() => {

		resetRedirects();

	});

	const onLogin = () => {
		setRedirectLogin(true);

	};

	const onLogout = () => {
		setRedirectRoot(true);

	};

	const resetRedirects = () => {
		setRedirectLogin(false);
		setRedirectRoot(false);

	};

	const { isLoggedIn, isAdmin }  = getLoginStatus();

	return (

				<>

				<Container fluid>
					<Row>

						<ListGroupItem className='menuHome'><NavLink exact to='/'>Owl&apos;s of Elgin</NavLink></ListGroupItem>
						<ListGroupItem className='menuItem'><NavLink exact to='/advertisers'>Owl&apos;s Advertisers</NavLink></ListGroupItem>
						<ListGroupItem className='menuItem'><NavLink to='#' onClick={linkToFacebook}>Owls on Facebook</NavLink></ListGroupItem>

						<ListGroupItem hidden={!isLoggedIn} className='menuItem'><NavLink exact to='/calendar'>Calendar</NavLink></ListGroupItem>
						<ListGroupItem hidden={!isLoggedIn} className='menuItem'><NavLink exact to='/officers'>Officers</NavLink></ListGroupItem>
						<ListGroupItem hidden={!isLoggedIn} className='menuItem'><NavLink exact to='/newsletter'>Newsletter</NavLink></ListGroupItem>
						<ListGroupItem hidden={!isLoggedIn} className='menuItem'><NavLink exact to='/archive'>Archive</NavLink></ListGroupItem>
						<ListGroupItem hidden={!isLoggedIn} className='menuItem'><NavLink exact to='/sponsors'>Sponsors</NavLink></ListGroupItem>

						<ListGroupItem hidden={!isAdmin} className='menuItem'><NavLink exact to='/admin'>Admin</NavLink></ListGroupItem>

						<LoginButton  onLogin={onLogin} onLogout={onLogout} />

					</Row>

				</Container>

				{redirectLogin ? <Redirect to='/login' /> : null}
				{redirectRoot ? <Redirect to='/' /> : null}

				</>

	);

};


const Header = () => {

	return (
		<Container fluid>

			<Row className="justify-content-center">
				<Col>
					<img src={headerLeft} alt="Owls of Elgin since 1933" />
				</Col>

				<Col className='mainHeaderCenter'>
					<h1>Owl&apos;s Athletic Club</h1>
					<h3>Elgin, Illinois</h3>
					<h4>Since 1933</h4>
				</Col>

				<Col>
					<img src={headerRight} alt="Owls of Elgin since 1933" />
				</Col>


			</Row>

			<Row className="menuContainer">

				<Navigation  />

			</Row>

		</Container>

	);

};


const linkToFacebook = () => {

	var win = window.open('https://facebook.com/owlsofelgin', '_blank');
	win.focus();


};

export default Header;