import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { apiGet } from './apiCall';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import './react-big-calendar.css';

const Calendar = () => {

	const today = new Date();
	const year = today.getFullYear();
	const month = ('0' + (today.getMonth()+1)).slice(-2);

	const [displaydate, setDisplayDate] = useState(today);
	const [endpoint, setEndpoint] = useState(`owlscalendar?year=${year}&month=${month}`);
	const [owlsCalendar, setOwlsCalendar] = useState();
	const [httpStatus, setHttpStatus] = useState(200);

	const localizer = momentLocalizer(moment);

	//
	// Hook to query database when the endpoint changes
	//
	useEffect(() => {

		apiGet(endpoint)
			.then(data => {

				if (data.content.status) {
					setHttpStatus(data.content.status);
				}
				else {
					setOwlsCalendar(data.content.OwlsCalendar);
					setHttpStatus(data.status);
				}

			})
			.catch(error => {
				setHttpStatus(error.status);
			});
	}, [endpoint]);

	switch (httpStatus){
	case 404:
		break;
	case 200:
		break;
	case 403:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/login' />;
	default:
		localStorage.removeItem('authToken');
		localStorage.removeItem('isAdmin');
		return <Redirect to='/' />;
	}

	//
	// Event Handler receives a new date
	//

	const onNavigate = (props) => {

		var newDate = new Date(props);

		setDisplayDate(newDate);

		var year = newDate.getFullYear();
		var month = ('0' + (newDate.getMonth()+1)).slice(-2);

		// Trigger useEffect to invoke the endpoint
		setEndpoint(`owlscalendar?year=${year}&month=${month}`);

	};

	var events;

	if (owlsCalendar && owlsCalendar.events) {
		events = owlsCalendar.events.map((event) => {
			event.start =  new Date(event.start);
			event.end = new Date(event.end);

			return event;

		});

	}

	return (
		<div>
			<div style={{textAlign: 'center'}}>
				{!events ? (
          <>
           <div>Loading...</div>
          </>
				) : (
					<BigCalendar
						localizer={localizer}
						views={['month', 'agenda']}
						events={events}
						date={displaydate}
						startAccessor="start"
						endAccessor="end"
						defaultView='month'
						style={{height: 1000}}
						onNavigate={onNavigate}
					/>
				)}
			</div>
		</div>
	);

};

export default Calendar;
