import React from 'react';
import { Redirect } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';

import Collapsible from 'react-collapsible';

import './Admin.css';

import OwlsNewsletterContent from './admin/OwlsNewsletterContent';
import OwlsOfTheMonth from './admin/OwlsOfTheMonth';
import DonationOfTheMonth from './admin/DonationOfTheMonth';

import getLoginStatus from './getLoginStatus';

const Admin = () =>  {

	const { isAdmin } = getLoginStatus();

	if (!isAdmin)
		return <Redirect to='/' />;


	return (
		<Container fluid>

			<h1 className='adminHeading'>Owl&apos;s Newsletter Administration</h1>

			<Collapsible trigger="Newsletter Content"  >

				{ OwlsNewsletterContent() }

			</Collapsible>

			<Collapsible trigger="Owls of the Month / Donation of the Month">

				<Row>
					<Col>
						{ OwlsOfTheMonth() }
					</Col>

					<Col>
						{ DonationOfTheMonth() }
					</Col>
				</Row>

			</Collapsible>

			<Collapsible trigger="Calendar">
				<p>Not Yet</p>

				<button>Save Calendar</button>
			</Collapsible>

			<Collapsible trigger="Officers">
				<p>Not Yet</p>

				<button>Save Officers</button>
			</Collapsible>

			<Collapsible trigger="Sponsors">
				<p>Not Yet</p>

				<button>Save Sponsors</button>
			</Collapsible>

			<Collapsible trigger="Advertisers">
				<p>Not Yet</p>

				<button>Save Advertisers</button>
			</Collapsible>

			<Collapsible trigger="Publish">
				<p>Not Yet</p>

				<button>Preview</button>
			</Collapsible>

		</Container>
	);

};


export default Admin;