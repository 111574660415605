
import React, {useState, useEffect} from 'react';
import {Row, Col, Image, Button, FormControl} from 'react-bootstrap';

import { apiGet, apiPut } from '../apiCall';

import DeleteImage from '../images/delete.svg';
import AddImage from '../images/add.svg';

import '../Admin.css';

const OwlsOfTheMonth = () => {
	const endPoint = 'owlsofthemonth';

	const [owlsOfTheMonth, setOwlsOfTheMonth] = useState([]);
	const [httpStatus, setHttpStatus] = useState(200);
	const [httpStatusMessage, setHttpStatusMessage] = useState('');
	const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

	const handleChange = (i, event) => {
		const values = [...owlsOfTheMonth];
		values[i] = event.target.value;

		var isDisabled = false;
		values.forEach(value => {
			if (!value.trim())
				isDisabled = true;
		});

		setIsUpdateDisabled(isDisabled);
		setOwlsOfTheMonth(values);
	};

	const handleAdd = () => {
		const values = [...owlsOfTheMonth];
		values.push( '' );
		setIsUpdateDisabled(true);
		setOwlsOfTheMonth(values);
	};

	const handleRemove = (i) => {
		const values = [...owlsOfTheMonth];
		values.splice(i, 1);

		setIsUpdateDisabled(false);

		setOwlsOfTheMonth(values);
	};

	const handleUpdate = () => {
		const payload = {};
		payload.OwlsOfTheMonth = [];

		owlsOfTheMonth.forEach((field) => {
			payload.OwlsOfTheMonth.push(field);
		});

		apiPut(endPoint, payload)
			.then(data => {
				setHttpStatus(data.status);

			})
			.catch(error => {
				setHttpStatus(error.status);

			});

		switch (httpStatus){

		case 200:
			setHttpStatusMessage('Update Complete');
			break;

		default:
			setHttpStatusMessage(`Error on update: ${httpStatus}`);

		}

		setIsUpdateDisabled(true);

	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setHttpStatusMessage('');
		}, 5000);
		return () => clearTimeout(timer);
	}, [httpStatusMessage]);

	useEffect(() => {

		apiGet(endPoint)
			.then(data => {
				const values = [...data.content.OwlsOfTheMonth ];
				setOwlsOfTheMonth(values);
				setHttpStatusMessage('');
			})
			.catch(error => {
				setHttpStatus(error.status);
				setHttpStatusMessage(`Error on GET: ${error.status}`);

			});
	}, []);

	var textList = owlsOfTheMonth.map((field, idx) => {

		return (

			<Row key={idx}>

				<Col xs={1}>
					<Image src={DeleteImage} onClick={() => handleRemove(idx)} />
				</Col>

				<Col xs={11}>
					<FormControl className='adminText'
						placeholder="Enter member"
						value={owlsOfTheMonth[idx]}
						onChange={e => handleChange(idx, e)} />
				</Col>

			</Row>

		);

	});

	return (

		<Row className='adminControl'>

			<Col xs={1}>

			</Col>

			<Col>
				<Row className='adminMarginTop adminMarginBottom'>
					<Col>Owls of the Month</Col>
				</Row>

				{textList}

				<Row className='adminMarginTop'>

					<Col xs={1}>
						<Image src={AddImage} onClick={() => handleAdd()} />
					</Col>
					<Col xs={11}>
						<Button className='adminButton' disabled={isUpdateDisabled} onClick={handleUpdate}>Update the Owls of the Month</Button>
					</Col>

				</Row>

				<Row className='adminMarginTop adminMarginBottom adminError'>
					<Col>
						{httpStatusMessage}
					</Col>
				</Row>
			</Col>

			<Col xs={2}></Col>

		</Row>

	);

};

export default OwlsOfTheMonth;