import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import OwlOfTheMonth from './OwlOfTheMonth';
import DonationOfTheMonth from './DonationOfTheMonth';

const HomeLoggedIn = () => {

	return (

		<Container fluid className='homeContainer'>

			<Row>
				<Col>
					<Row className="justify-content-center" >
						<OwlOfTheMonth />
					</Row>

				</Col>

				<Col>
					<Row className="justify-content-center" >
						<DonationOfTheMonth />
					</Row>
				</Col>

			</Row>

		</Container>

	);


};

export default HomeLoggedIn;